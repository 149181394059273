* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0;
  min-width: 100%;
}

.site-container {
  overflow-x: hidden;
}
.rating span {
  color: #f0c040;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.img-larg {
  max-width: 100%;
}
.img-thumbnail {
  height: 100px;
}
.small-container {
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 20px 10px 40px 0px #999;
  background-color: #fff;
  padding: 2rem;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product {
  border: 1px solid rgb(225, 217, 217);
  box-shadow: 7px 3px 7px 3px #f5ebd1;
  margin: 0.7rem;
}
.product img {
  width: 100%;
  max-width: 400px;
  max-height: 202px;
}
.product-info {
  padding: 1rem;
}
/* sidebar*/
.navbar-brand {
  font-weight: bold;
}

.site-container {
  transition: 0.4s;
}
.active-cont {
  margin-left: 240px;
}
.active-nav {
  left: 0;
}
.text-bold {
  font-weight: bold;
}
.cardColor1 {
  background-color: #292929ec;
  border-radius: 8px;
}
.cardColor2 {
  background-color: #007aff;
  color: white;
}
.cardColor3 {
  background-color: #34db45;
  font-weight: bold;
  color: white;
}
.link {
  display: block;
  color: #fff;
  margin-bottom: 10px;
  font-size: 16px;
  text-decoration: none;
}
.link:hover {
  color: green;
  transition: 200ms ease-in-out;
}

/* sidebar */
.minHieght {
  min-height: calc(100vh - 56px) !important;
}

.fpTHfu {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  color: white;
}
.card-img-overlay {
  top: 40%;
}

@media screen and (max-width: 512px) {
  .prolist {
    overflow-y: hidden;
  }
  .btn-primary {
    width: 100%;
  }

  /* .bOQEtf {
    width: 100% !important;
  } */
}

/* @media screen and (min-width: 512px) and (max-width: 1024px) {
  .bOQEtf {
    width: 100% !important;
    min-width: 0 !important;
  }
} */
.bOQEtf {
  width: 100% !important;
}
a {
  text-decoration: none;
  color: black;
}
.btn-primary,
.btn-outline-primary {
  border: none;
  outline: none;
  background-color: #f0c030;
  color: white;
}
.btn-head {
  font-size: 2rem;
  padding: 10px 30px;
  font-weight: bold;
}
.table {
  padding-right: 1rem;
}
.head-title {
  color: #fcb900;
  font-weight: bolder;
  margin-bottom: 2rem;
  font-size: 4rem;
}
